import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src477529347/src/autopatcher-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "pre-and-post-patching-actions---hooks"
    }}>{`Pre and post patching actions - Hooks`}</h1>
    <h2 {...{
      "id": "general-description"
    }}>{`General description`}</h2>
    <p>{`If you need to execute some actions before or/and after update you can use `}<strong parentName="p">{`Pre&Post Actions AutoPatcher feature`}</strong>{`. It allows you to specify list of `}<em parentName="p"><strong parentName="em">{`AWS Lambda`}</strong></em>{` ARNs or `}<em parentName="p"><strong parentName="em">{`Azure Functions`}</strong></em>{` which should be executed before or/and after update.`}</p>
    <p>{`Pre&Post Actions Auto-Patcher feature allows to:`}</p>
    <ul>
      <li parentName="ul">{`start/stop instances`}</li>
      <li parentName="ul">{`tag instances`}</li>
      <li parentName="ul">{`create snapshots`}</li>
      <li parentName="ul">{`many others, just write your lambda or Azure functions`}</li>
    </ul>
    <h3 {...{
      "id": "hook-types"
    }}>{`Hook types`}</h3>
    <p>{`In AutoPatcher those actions are called `}<strong parentName="p">{`hooks`}</strong>{` and can be divided into groups in the following way:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Plan-global`}</strong>{` hooks, a.k.a. `}<strong parentName="li">{`event-global`}</strong>{` hooks, standard hooks or simply `}<strong parentName="li">{`hooks`}</strong>{` (as opposite to `}<strong parentName="li">{`host-hooks`}</strong>{` described below): hooks that are defined per plan`}
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`Pre hooks`}</strong>{`: executed before `}<em parentName="li">{`any`}</em>{` machine starts patching`}</li>
          <li parentName="ul"><strong parentName="li">{`Post hooks`}</strong>{` which are executed:`}
            <ul parentName="li">
              <li parentName="ul">{`after `}<em parentName="li">{`all`}</em>{` machines finished patching`}</li>
              <li parentName="ul">{`only if there was no failed pre hook`}</li>
              <li parentName="ul">{`regardless of the machines patching statuses`}</li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Machine-specific`}</strong>{` (a.k.a. `}<strong parentName="li">{`host hooks`}</strong>{`): hooks that are defined per machine in a plan`}
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`Pre host hooks`}</strong>{`: executed before the machine starts patching`}</li>
          <li parentName="ul"><strong parentName="li">{`Post host hooks`}</strong>{` which are executed:`}
            <ul parentName="li">
              <li parentName="ul">{`after the machine finished patching`}</li>
              <li parentName="ul">{`only if there was no failed pre host hooks for this machine`}</li>
              <li parentName="ul">{`regardless of the machine patching status`}</li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <p>{`More detailed description of each hook type and how to configure them is below.`}</p>
    <h2 {...{
      "id": "plan-global-hooks"
    }}>{`Plan-global hooks`}</h2>
    <p>{`Pre/post plan-global hook can be of any of the following types:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`AWS Lambda`}</strong>{` ARN`}</li>
      <li parentName="ul">{`HTTPS endpoint of an `}<strong parentName="li">{`Azure Function`}</strong></li>
    </ul>
    <h3 {...{
      "id": "plan-global-hooks-execution-flow"
    }}>{`Plan-global hooks execution flow`}</h3>
    <p>{`Below the flow of the pre hooks is shown.`}</p>
    <p><img alt="hooks-diagram" src={require("../assets/hooks/hooks-diagram.png")} /></p>
    <h3 {...{
      "id": "hook-fail-policy"
    }}>{`Hook fail policy`}</h3>
    <p>{`The following policies apply:`}</p>
    <ul>
      <li parentName="ul">{`If a pre hook fails, AutoPatcher won't patch any machine proces and finish execution with status failed. A notification about an error will be sent. However all hooks after the failed one will be executed anyway.`}</li>
      <li parentName="ul">{`Due to the fact machine update won't be started if pre hook failed you can use it to do actions like starting machines.`}</li>
      <li parentName="ul">{`If a post hook fails, AutoPatcher will finish execution with status failed and send notification about it.`}</li>
      <li parentName="ul">{`You can use post hooks to stop machines or check if customer application is running after the update.`}</li>
    </ul>
    <h2 {...{
      "id": "how-to-configure-hooks"
    }}>{`How to configure hooks`}</h2>
    <h3 {...{
      "id": "creating-hooks-using-aws-lambda"
    }}>{`Creating hooks using AWS Lambda`}</h3>
    <p>{`This section describe how to create and configure pre and post hooks in patching process using AWS Lambda.`}</p>
    <p>{`First you need to create AWS Lambda function.`}</p>
    <p>{`You should allow your lambda to be invoked by the AutoPatcher production account. To do it you need to set Lambda permissions policy. It's not possible to do it using AWS console but you can do it using the AWS CLI like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`aws lambda add-permission --function-name your_lambda_name --statement-id StatementName --action 'lambda:InvokeFunction' --principal 'XXXXXXXXXXXX'
`}</code></pre>
    <p>{`where `}<inlineCode parentName="p">{`XXXXXXXXXXXX`}</inlineCode>{` is the AWS Account ID where AutoPatcher is deployed (currently it's `}<inlineCode parentName="p">{`286863837419`}</inlineCode>{`).`}</p>
    <h4 {...{
      "id": "adding-lambda-arn-to-schedule-plan-in-the-ui"
    }}>{`Adding Lambda ARN to schedule plan in the UI`}</h4>
    <p><img alt="aws-lambda-arn" src={require("../assets/hooks/aws-lambda-arn.png")} /></p>
    <h3 {...{
      "id": "creating-hooks-using-azure-functions"
    }}>{`Creating hooks using Azure Functions`}</h3>
    <p>{`Using Azure Functions is very similar to AWS Lambdas. To configure Azure pre/post hooks you need:`}</p>
    <ul>
      <li parentName="ul">{`Create Azure function triggered by the `}<inlineCode parentName="li">{`GET`}</inlineCode>{` http request. If you want to trigger function using POST method you have to add `}<inlineCode parentName="li">{`method: "POST"`}</inlineCode>{` field`}</li>
      <li parentName="ul">{`Get function URL with security code (e.g. `}<a parentName="li" {...{
          "href": "https://functions3a416041.azurewebsites.net/api/HttpTriggerJS1?code=D6eLJ5XkahSab/qQYeipxL54MwhMKoDPKdAuABitVaYTI3LYjthgtQ=="
        }}>{`https://functions3a416041.azurewebsites.net/api/HttpTriggerJS1?code=D6eLJ5XkahSab/qQYeipxL54MwhMKoDPKdAuABitVaYTI3LYjthgtQ==`}</a>{`)`}</li>
      <li parentName="ul">{`Add this link into pre hooks or post hooks list instead of lambda arn. Please remember to specify type of the hook (azure in here).`}</li>
    </ul>
    <h4 {...{
      "id": "specifying-azure-hook-in-user-interface-in-plan-creatingediting"
    }}>{`Specifying Azure hook in user interface in plan creating/editing`}</h4>
    <p><img alt="azure" src={require("../assets/hooks/azure.png")} /></p>
    <h2 {...{
      "id": "host-hooks-machine-specific"
    }}>{`Host hooks (machine-specific)`}</h2>
    <p>{`These hooks are applied for every machine separately but creating and configuring them is similar to standard hooks.
The main difference is that in addition to AWS Lambdas and Azure Functions the user can use
custom scripts as a source for a host hook.`}</p>
    <h3 {...{
      "id": "uploading-the-script-files"
    }}>{`Uploading the script files`}</h3>
    <p>{`The script files (shell scripts for Linux machines and PowerShell scripts for Windows)
need to be uploaded to an S3 bucket and made readable by the AutoPatcher AWS account.
This can be achieved in two ways:`}</p>
    <ul>
      <li parentName="ul">{`Make the S3 object publicly readable (which may not be acceptable to the customer for security reasons)`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`aws s3api put-object-acl --bucket <YOUR_BUCKET> --key <SCRIPT_PATH> --acl public-read
`}</code></pre>
    <ul>
      <li parentName="ul">{`Give the read permissions explicitly to the AutoPatcher production AWS account only. For this the user would need
a canonical user ID of the AutoPatcher AWS account which is provided in the script below`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`aws s3api put-object-acl --bucket <YOUR_BUCKET> --key <SCRIPT_PATH> --grant-read id=708e30907d5e6a5baeb54773a593ca5012c5d08cfc011376f6e960922aad3faf
`}</code></pre>
    <p>{`To specify host hook you need to attach it to a machine in a plan by giving it's type and source link.`}</p>
    <h3 {...{
      "id": "host-hooks-execution-flow"
    }}>{`Host hooks execution flow`}</h3>
    <p><img alt="host-hooks-diagram" src={require("../assets/hooks/host-hooks-diagram.png")} /></p>
    <h3 {...{
      "id": "adding-host-hook-in-a-static-plan"
    }}>{`Adding host hook in a static plan`}</h3>
    <p><img alt="static-1" src={require("../assets/hooks/static-1.png")} /></p>
    <p><img alt="static-2" src={require("../assets/hooks/static-2.png")} /></p>
    <h3 {...{
      "id": "adding-host-hook-in-a-dynamic-plan"
    }}>{`Adding host hook in a dynamic plan`}</h3>
    <p><img alt="dynamic" src={require("../assets/hooks/dynamic.png")} /></p>
    <h4 {...{
      "id": "host-hooks-fail-policy"
    }}>{`Host hooks fail policy`}</h4>
    <p>{`The following policies apply:`}</p>
    <ul>
      <li parentName="ul">{`If pre host hook fails, AutoPatcher will let other machines, which are already updating, finish (if update is in parallel mode) and won't continue patching process for the rest of machines, and will finish execution with failed status. A notification about an error will be sent.`}</li>
      <li parentName="ul">{`You can use it to check service status (like Docker, DB server, etc.) on some machines.`}</li>
      <li parentName="ul">{`If post host hook fails, AutoPatcher won't continue patching process for the rest of machines, and will finish execution with failed status. A notification about an error will be sent.`}</li>
      <li parentName="ul">{`You can use it to check if service is still working after update on some machines.`}</li>
    </ul>
    <h2 {...{
      "id": "hooks-inputoutput-payload"
    }}>{`Hooks input/output payload`}</h2>
    <h3 {...{
      "id": "input-format"
    }}>{`Input format`}</h3>
    <p>{`AutoPatcher provides a JSON payload as an input when it executes a hook. Its format is described below:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "event_id": string,
    "customer_id": string,
    "machines": [
        {
            "id": string,
            "status": string,
            "name": string,
            "subscription_id": string,
            "subscription_name": string,
            "resource_group_name": string
        },
        ...
    ],
    "hook_outputs": [
        {
            "code": int,
            "message": string,
            "hook": {
                "type": string,
                "source": string,
                "method": string,
                "name": string
            },
            "plan_name": string,
            "event_id": string,
            "output": string,
            "machine_id": string,
            "command_id": string
        },
        ...
    ]
}
`}</code></pre>
    <h4 {...{
      "id": "fields-description"
    }}>{`Fields description`}</h4>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`machines`}</inlineCode>{` - contains a list of machines from the current patching event .`}
        <ul parentName="li">
          <li parentName="ul">{`If the hook is executed is a host hook then this list will contain only the machine this hook is defined for.`}</li>
          <li parentName="ul">{`If the hook is executed as a part of event's partial execution (`}<a parentName="li" {...{
              "href": "/patching-events#partial-patching"
            }}>{`read more`}</a>{`).`}</li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`hook_outputs`}</inlineCode>{` - contains a list of all hooks that were executed prior to the current hook execution along with their outputs.
More information is provided in the `}<a parentName="li" {...{
          "href": "#hook-outputs-propagation"
        }}>{`Hook outputs propagation`}</a>{` section.`}
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`plan_name`}</inlineCode>{` and `}<inlineCode parentName="li">{`event_id`}</inlineCode>{` are only present for a patching event from a pipeline (`}<a parentName="li" {...{
              "href": "/scheduling-patching#scheduling-a-patching-plan-pipeline"
            }}>{`read more`}</a>{`)`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`command_id`}</inlineCode>{` contains an ID of the SSM command and is present only for host hooks of type `}<inlineCode parentName="li">{`script`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`hook`}</inlineCode>{` contains the definition of the hook. The `}<inlineCode parentName="li">{`name`}</inlineCode>{` field in it can be `}<inlineCode parentName="li">{`pre_0`}</inlineCode>{`, `}<inlineCode parentName="li">{`post_1`}</inlineCode>{`, `}<inlineCode parentName="li">{`pre_host_1`}</inlineCode>{` and so on. Note that hooks are counted from 0.`}</li>
        </ul>
      </li>
    </ul>
    <h3 {...{
      "id": "output-format"
    }}>{`Output format`}</h3>
    <p>{`The return value of the hook function should be the following JSON object:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "code": number
    "msg": string
    "output": string
}
`}</code></pre>
    <h4 {...{
      "id": "fields-description-1"
    }}>{`Fields description`}</h4>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`code`}</inlineCode>{` - a number indicating hook's execution status. If it's in 200-299 range the hook is considered `}<strong parentName="li">{`successful`}</strong>{`. If it's greater than 299 it's considered `}<strong parentName="li">{`failed`}</strong>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`msg`}</inlineCode>{` - a text message that can be shown in the GUI.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`output`}</inlineCode>{` - an arbitrary string that will be can be used to pass some data to other hooks. More is described in the next section.`}</li>
    </ul>
    <h3 {...{
      "id": "hook-outputs-propagation"
    }}>{`Hook outputs propagation`}</h3>
    <p>{`After a hook is executed the fields `}<inlineCode parentName="p">{`code`}</inlineCode>{`, `}<inlineCode parentName="p">{`msg`}</inlineCode>{` and `}<inlineCode parentName="p">{`output`}</inlineCode>{` from its output are stored and propagated to other hooks
through the `}<inlineCode parentName="p">{`hook_outputs`}</inlineCode>{` field in the following manner:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`code`}</inlineCode>{` -> `}<inlineCode parentName="li">{`hook_outputs[].code`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`msg`}</inlineCode>{` -> `}<inlineCode parentName="li">{`hook_outputs[].message`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`output`}</inlineCode>{` -> `}<inlineCode parentName="li">{`hook_outputs[].output`}</inlineCode></li>
    </ul>
    <p>{`This feature allows a hook to get access to the data some other hook had generated
(this includes not only the hooks from the same event as the current hook, but from all the previous events
in the same pipeline execution). For example, a pre hook can check all the machines in the plan and start those
that are stopped and then return the list of their IDs as output.
Then a post hook can retrieve this output and stop only those machines that were started in the pre hook.`}</p>
    <h2 {...{
      "id": "starting-and-stoping-azure-vm-in-hooks"
    }}>{`Starting and stoping Azure VM in hooks`}</h2>
    <h3 {...{
      "id": "create-azure-active-directory-app-registration"
    }}>{`Create Azure Active Directory App registration`}</h3>
    <p>{`To create app registration go to Azure Active Directory → App registrations → New registration and provide meaningful name (rest can stay default).`}</p>
    <p>{`After creation go to created app registration and navigate to Certificates and Secret Keys and generate new secret key, it will be needed later.`}</p>
    <h3 {...{
      "id": "assign-role-to-all-vms"
    }}>{`Assign role to all VMs`}</h3>
    <p>{`To assign role go to Virtual Machines and for each machine (that needs to be started and stopped) go to:`}</p>
    <p>{`IAM → Add role assignment, select Virtual Machine Contributor role and in Select search for your App registration name.`}</p>
    <h3 {...{
      "id": "create-functions"
    }}>{`Create functions`}</h3>
    <p>{`This scripts will only work for machines that are added in AutoPatcher with their Azure Subscription Id and Resource Group Name. Rest of the machines will be skipped.`}</p>
    <p>{`Both scripts require ms-rest-azure and azure-arm-compute packages which can be installed via npm.`}</p>
    <p>{`Create functions in Node.js environment, I will not describe all steps here, because there are plenty of ways to do it (I've used azure cli and vs code azure functions plugin). Verification method should be anonymous and function should support HTTP POST method.`}</p>
    <p>{`Code for starting machines (with waiting for boot):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const msRestAzure = require('ms-rest-azure');
const ComputeManagementClient = require('azure-arm-compute');

module.exports = async function (context, req) {
    try {
        let machines = req.body.machines;
        let creds = await msRestAzure.loginWithServicePrincipalSecret(process.env.AZURE_CLIENT_ID, process.env.AZURE_APPLICATION_SECRET, process.env.AZURE_TENANT);
        let waitObjects = [];
        for (let machine of machines) {
            if (!machine.subscription_id || !machine.name || !machine.resource_group_name) {
                continue;
            }
            try {
                let computeClient = new ComputeManagementClient(creds, machine.subscription_id);
                waitObjects.push(computeClient.virtualMachines.start(machine.resource_group_name, machine.name.split('.')[0]));
            } catch (error) {
                context.log(\`Error while starting \${machine.name} - \${error.message}\`)
            }
        }
        await Promise.all(waitObjects);
        context.res = httpRes(200, "Instances started");
    } catch (error) {
        context.res = httpRes(500, error.message)
    }
};

httpRes = (code, msg) => {
    return {
        status: code,
        body: {
            "code": code,
            "msg": msg,
        }
    }
}
`}</code></pre>
    <p>{`Code for stopping machines (without waiting). Note that there two ways of stopping vm - with (still billed) and without releasing resources:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const msRestAzure = require('ms-rest-azure');
const ComputeManagementClient = require('azure-arm-compute');

module.exports = async function (context, req) {
    try {
        let machines = req.body.machines;
        let creds = await msRestAzure.loginWithServicePrincipalSecret(process.env.AZURE_CLIENT_ID, process.env.AZURE_APPLICATION_SECRET, process.env.AZURE_TENANT);
        for (let machine of machines) {
            if (!machine.subscription_id || !machine.name || !machine.resource_group_name) {
                continue;
            }
            try {
                let computeClient = new ComputeManagementClient(creds, machine.subscription_id);
                // power off and do not release resources (billed):
                // computeClient.virtualMachines.powerOff(machine.resource_group_name, machine.name.split('.')[0]);
                // power off and release resources (not billed):
                computeClient.virtualMachines.deallocate(machine.resource_group_name, machine.name.split('.')[0]);
            } catch (error) {
                context.log(\`Error while stopping \${machine.name} - \${error.message}\`)
            }
        }
        context.res = httpRes(200, "Instances stopping initiated");
    } catch (error) {
        context.res = httpRes(500, error.message)
    }
};

httpRes = (code, msg) => {
    return {
        status: code,
        body: {
            "code": code,
            "msg": msg,
        }
    }
}
`}</code></pre>
    <h3 {...{
      "id": "add-env-variables-for-both-functions"
    }}>{`Add env variables for both functions`}</h3>
    <p>{`To add environmental variables for Azure Function go to their application settings.`}</p>
    <p>{`Both functions require 3 variables:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`AZURE_CLIENT_ID`}</inlineCode>{` - ID of an app registration created in the first step.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`AZURE_APPLICATION_SECRET`}</inlineCode>{` - Secret key generated in the first step.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`AZURE_TENANT`}</inlineCode>{` - Azure Active Directory ID (in Properties).`}</li>
    </ul>
    <h3 {...{
      "id": "pass-functions-as-hooks"
    }}>{`Pass functions as hooks`}</h3>
    <p>{`The last step is to add both functions as hooks (also host hooks) to AutoPatcher. The starting script probably should be the first one in pre hooks section and the stopping script should probably be the last one in post hooks. HTTP method should not matter.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      